var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.modalOptions.modalContainerClass || "" }, [
    _c(
      "section",
      {
        ref: "modalContainer",
        staticClass: "modal si-modal shared-modal animated",
        class: _vm.modalOptions.modalClasses || "",
        attrs: {
          tabindex: _vm.modalOptions.removeTabIndex ? "" : "-1",
          id: _vm.modalOptions.modalId,
          role: "dialog",
          "data-card-modal": "true",
          "data-id": _vm.modalOptions.modalID || "",
          "data-keyboard": _vm.keyBoardOption,
          "data-trigger-active-container":
            _vm.modalOptions.triggerActiveContainer
        },
        on: {
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            ) {
              return null
            }
            return _vm.$emit("escapePressed")
          }
        }
      },
      [
        _c(
          "article",
          {
            staticClass: "modal-dialog",
            class: _vm.modalOptions.modalDialogClass
          },
          [
            _c(
              "div",
              {
                staticClass: "modal-content",
                class: _vm.modalOptions.pickerOverFlowClass
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-header default m-b-10",
                    class: {
                      "p-t-b-22":
                        _vm.modalOptions.backButton ||
                        _vm.modalOptions.leftAlign
                    },
                    style: {
                      "border-top": _vm.modalOptions.topBarColor
                        ? "4px solid " + _vm.modalOptions.topBarColor
                        : "none"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "fs-11 text-uppercase semibold text-center m-t-bar",
                        class: {
                          "title-left-align":
                            _vm.modalOptions.backButton ||
                            _vm.modalOptions.leftAlign
                        }
                      },
                      [
                        _vm.modalOptions.backButton
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "btn-icon product-back-icon pointer",
                                on: { click: _vm.moveBack }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "24",
                                      height: "24",
                                      viewBox: "0 0 24 24"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        fill: "#14153A",
                                        "fill-rule": "evenodd",
                                        d:
                                          "M19 11.001H7.14l3.63-4.36a1.001 1.001 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13a1 1 0 0 0-.07.36 1 1 0 0 0 .07.36c0 .05 0 .08.07.13.026.052.056.103.09.15l5 6a1 1 0 0 0 1.41.13 1 1 0 0 0 .13-1.41l-3.63-4.36H19a1 1 0 1 0 0-2z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", {
                          staticClass:
                            "text-center d-block text-truncate title-product",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.modalOptions.title || "Sample Modal"
                            )
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("button", {
                      staticClass: "modal-close-btn",
                      attrs: { type: "button", "data-dismiss": "modal" }
                    }),
                    _vm._v(" "),
                    _vm._m(0)
                  ]
                ),
                _vm._v(" "),
                _c(
                  "article",
                  {
                    staticClass: "modal-body",
                    class: _vm.modalOptions.body_class
                  },
                  [_vm._t("modal-icon"), _vm._v(" "), _vm._t("modal-content")],
                  2
                )
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "ajax-loading si-page-loader",
        attrs: { "data-behavior": "ajax-loader" }
      },
      [
        _c("div", { staticClass: "bar bar-1" }),
        _vm._v(" "),
        _c("div", { staticClass: "bar bar-2" }),
        _vm._v(" "),
        _c("div", { staticClass: "bar bar-3" }),
        _vm._v(" "),
        _c("div", { staticClass: "bar-complete bar-1" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }