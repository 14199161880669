<template>
  <div :class="modalOptions.modalContainerClass || ''">
    <section class='modal si-modal shared-modal animated' :tabindex="modalOptions.removeTabIndex ? '' : '-1'" :id="modalOptions.modalId" role='dialog' data-card-modal='true' :data-id="modalOptions.modalID || ''" ref='modalContainer' :class="modalOptions.modalClasses || '' " :data-keyboard='keyBoardOption' @keydown.esc="$emit('escapePressed')" :data-trigger-active-container="modalOptions.triggerActiveContainer">
      <article class='modal-dialog' :class="modalOptions.modalDialogClass">
        <div class='modal-content' :class="modalOptions.pickerOverFlowClass">
          <div class='modal-header default m-b-10' :style="{'border-top' : modalOptions.topBarColor ? '4px solid ' + modalOptions.topBarColor : 'none' }" :class="{'p-t-b-22': (modalOptions.backButton || modalOptions.leftAlign)}">
            <div class="fs-11 text-uppercase semibold text-center m-t-bar" :class="{'title-left-align': (modalOptions.backButton || modalOptions.leftAlign) }">
              <span v-if="modalOptions.backButton" class="btn-icon product-back-icon pointer" @click="moveBack">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="#14153A" fill-rule="evenodd" d="M19 11.001H7.14l3.63-4.36a1.001 1.001 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13a1 1 0 0 0-.07.36 1 1 0 0 0 .07.36c0 .05 0 .08.07.13.026.052.056.103.09.15l5 6a1 1 0 0 0 1.41.13 1 1 0 0 0 .13-1.41l-3.63-4.36H19a1 1 0 1 0 0-2z"/>
                </svg>
              </span>
              <span class="text-center d-block text-truncate title-product" v-html="modalOptions.title || 'Sample Modal'"></span>
            </div>
            <button type='button' class='modal-close-btn' data-dismiss='modal'></button>
            <div data-behavior="ajax-loader" class="ajax-loading si-page-loader">
              <div class="bar bar-1"></div>
              <div class="bar bar-2"></div>
              <div class="bar bar-3"></div>
              <div class="bar-complete bar-1"></div>
            </div>
          </div>
          <article class='modal-body' :class='modalOptions.body_class'>
            <slot name="modal-icon"></slot>
            <!-- Modal Content -->
            <slot name="modal-content"></slot>
            <!--  ___________  -->
          </article>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  props: ['modalOptions'],
  mounted() {
    const _this = this;
    $(this.$refs.modalContainer).on('shown.bs.modal', function(event) {
      // This Timeout Makes 'Shown' event Async which renders the modal so that it runs after entire modal DOM is loaded
      _this.$emit('beforeShow');
      setTimeout(() => {
        _this.initToolTip();
        _this.$emit('onShown', event);
      });
    });
    $(this.$refs.modalContainer).on('hidden.bs.modal', function() {
      _this.$emit('onDismiss');
    });
  },
  methods: {
    isVisible() {
      return $(this.$refs.modalContainer).hasClass('in')
    },
    initToolTip() {
      $(this.$el).find('[data-toggle=tooltip]').tooltip();
    },
    focusModal() {
      $(this.$refs.modalContainer).focus();
      $('body').addClass('modal-open');
    },
    displayModal(animationRequired = false) {
      $(this.$refs.modalContainer).removeClass('fadeOut');
      if(animationRequired) {
        this.displayModalWithAnimation();
      }
      $(this.$refs.modalContainer).modal('show');
    },
    displayModalWithAnimation() {
      $(this.$refs.modalContainer).addClass('fadeIn');
    },
    hideModalWithAnimation() {
      $(this.$refs.modalContainer).addClass('fadeOut');
      setTimeout(() => {
        this.hideModalContainer();
      }, gon.lineItemSwitchDelay);
    },
    hideModal(animationRequired = false) {
      $(this.$refs.modalContainer).removeClass('last-modal fadeIn');
      if(animationRequired) {
        this.hideModalWithAnimation();
      } else {
        this.hideModalContainer();
      }
    },
    hideModalContainer: function() {
      $(this.$refs.modalContainer).modal('hide');
    },
    addModalClass(className) {
      $(this.$refs.modalContainer).addClass(className);
    },
    removeModalClass(className) {
      $(this.$refs.modalContainer).removeClass(className);
    },
    moveBack() {
      this.$emit('backButtonPressed');
    }
  },

  computed: {
    keyBoardOption: function() {
      if(typeof(this.modalOptions.keyboard) == 'undefined') {
        return "true";
      } else {
        return this.modalOptions.keyboard.toString();
      }
    }
  }
};
</script>

<!--
This is Shared SI Modal which can be used throught the app. It takes a prop :
modalOptions = {
  title : ,
  ?modalContainerClass : '',
  ?topBarColor:'',
  ?pickerOverFlowClass:'',
  ?modalID:''
}

Modal Content will be supplied via slot named ==> 'modal-content'
-->
